.imageSkeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 200px;
  height: 130px;
}

.imageSkeletonIcon {
  font-size: 40px;
  animation: icon 2s linear infinite;
}

@keyframes icon {
  from {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

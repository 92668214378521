// Import antd styles
@import "~antd/dist/antd.less";

// Antd overrides
@font-family: "Baloo 2", cursive;
@font-size-base: 16px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 14px;

@mobile-screen: ~"only screen and (max-width: 991px)";
@desktop-screen: ~"only screen and (min-width: 992px)";

.logo {
  margin-left: 25px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  :hover {
    color: #ea4435;
  }
}

.section {
  margin-top: 24px;
  margin-bottom: 48px;
}

.title-block {
  margin-bottom: 12px !important;
}

.title {
  display: inline-block;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  text-transform: uppercase;
}

.sub-title {
  display: inline-block;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  text-transform: uppercase;
}

.question-header-title {
  display: inline-block;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  text-transform: uppercase;
}

.sub-title-icon {
  vertical-align: text-top;
  margin-right: 8px;
}

.button-with-header {
  @media @mobile-screen {
    margin-top: 12px;
    margin-top: 0;
  }
  margin-top: -24px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
}

.ant-descriptions {
  margin: 5px 0;
  padding: 15px;
  border: 1px solid #efefef;
  .ant-descriptions-title {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-descriptions-item {
    padding-bottom: 0;
  }
}

.ant-descriptions.stretch-row {
  .ant-descriptions-view table {
    width: 100% !important;
  }
}

.ant-descriptions.danger-zone {
  background: repeating-linear-gradient(
    -45deg,
    #ec5816,
    #ec5816 10px,
    #dc4652 10px,
    #dc4652 20px
  );

  .ant-descriptions-item-content {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
}

@primary-color: #ea4435;
.login-container {
  height: 100vh;
  .login-container-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .login-logo {
      width: 50%;
      height: 50%;
      margin-bottom: 24px;
    }
    .login-button {
      margin-top: 24px;
    }
  }
}

@primary-color: #ea4435;